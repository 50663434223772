import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import ComponentOverview from 'components/ComponentOverview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "building-blocks"
    }}>{`Building blocks`}</h2>
    <p>{`Components are one of the key building blocks of the design system. Each
component has been designed and coded to solve a specific UI problem, such as
presenting a list of options, enabling submission of a form, providing feedback
to the user, and so on. All of the components in Carbon have been designed to
work harmoniously together, as parts of a greater whole.`}</p>
    <ComponentOverview mdxType="ComponentOverview" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      